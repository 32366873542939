import { ModalProps } from 'antd/es/modal'
import {
  CHECKPOINT_ACTION,
  CHECKPOINT,
  CHECKOUT_CHECKPOINT_ACTION_VALUES,
  LOGIN_CHECKPOINT_ACTION_VALUES,
  RETURN_CHECKPOINT_ACTION_VALUES,
  RuleResponse,
  RULE_STATUS,
} from '@signifyd/http'
import {
  DecisionCenterIcon,
  ReturnCheckpointMiniIcon,
} from '@signifyd/components'

export const DATE_TIME_FORMAT = 'M/D/YYYY h:mm A'

export const GET_RULES_LIMIT = 1000

export const NAV_BAR_HEIGHT = 52
export const SPACE_MD = 24

export const SIG_MODAL_PROPS: Partial<ModalProps> = {
  okType: 'link',
  cancelButtonProps: {
    type: 'link',
  },
  style: { top: 92 },
  maskClosable: false,
  width: 604,
  closable: false,
  destroyOnClose: true,
}

export const PAGE_PADDING = 40

export const checkpointToActionMap: Record<
  CHECKPOINT,
  Array<CHECKPOINT_ACTION>
> = {
  [CHECKPOINT.CHECKOUT]: CHECKOUT_CHECKPOINT_ACTION_VALUES,
  [CHECKPOINT.LOGIN]: LOGIN_CHECKPOINT_ACTION_VALUES,
  [CHECKPOINT.RETURN]: RETURN_CHECKPOINT_ACTION_VALUES,
}

/**
 * We want to change a checkpoint action tag's icon depending on its owning policy's checkpoint
 */
export const checkpointActionIconMap: Record<CHECKPOINT, any> = {
  [CHECKPOINT.CHECKOUT]: DecisionCenterIcon,
  [CHECKPOINT.LOGIN]: DecisionCenterIcon,
  [CHECKPOINT.RETURN]: ReturnCheckpointMiniIcon,
}

export const isPublishedPolicy = (policy: RuleResponse): boolean => {
  return (
    policy.ruleStatus === RULE_STATUS.ACTIVE ||
    policy.ruleStatus === RULE_STATUS.SCHEDULED ||
    policy.ruleStatus === RULE_STATUS.EXPIRED
  )
}

export const CATCH_ALL_PATH = '/catchall'

export enum UPLOAD_ENTRIES_METHOD {
  MANUAL = 'MANUAL',
  CSV = 'CSV',
}

export const GET_LIST_ENTRIES_LIMIT = 50

export const MAX_MANUAL_LIST_ENTRY_ITEMS = 25
