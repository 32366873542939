import { FC, useEffect, useState } from 'react'
import { Button, Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Layout, Space, Text, Tooltip } from '@signifyd/components'
import { RULE_STATUS } from '@signifyd/http'
import { useStoreActions, useStoreState } from 'stores'
import NewRuleSteps from 'core/components/NewRuleSteps'
import RulePageHeader from 'core/containers/NewRulePageHeader'
import DiscardRuleCreationPopconfirm from 'core/components/DiscardRuleCreationPopconfirm'
import TestTeamBanner from 'core/components/TestTeamBanner'
import { getPredictionListParamsForTeam } from 'core/http'
import PolicyHeader from 'core/containers/PolicyHeader'
import { useConditionTreeStore } from 'stores/conditionTree/ConditionTreeStore'
import RuleConditions from './containers/RuleConditions'
import RuleRecommendedActionSelect from './containers/RuleRecommendedActionSelect'
import EditReasonTextArea from './components/EditReasonTextArea/EditReasonTextArea'
import styles from './PolicyConditionsPage.less'
import { isConditionTreeValid } from './PolicyConditionsPage.utils'

interface Props {
  isEditing: boolean
  onDiscard: () => void
  onDenyEditRule: () => void
}

const { Content, Footer } = Layout

const PolicyConditionsPage: FC<Props> = ({
  isEditing,
  onDiscard,
  onDenyEditRule,
}) => {
  const { t } = useTranslation()
  const { ruleId } = useParams()
  const navigate = useNavigate()

  const [editReason, setEditReason] = useState<string>()
  const conditionTree = useConditionTreeStore((state) => state.conditionTree)

  const { policy } = useStoreState((state) => state.ruleModel)

  const isPolicyConditionEditable = policy?.ruleStatus === RULE_STATUS.DRAFT

  const nextStepButtonEnabled =
    !!policy?.ruleOutcome?.ruleRecommendedAction &&
    isConditionTreeValid(conditionTree)

  const teamId = policy?.teamId

  const isCurrentPolicy =
    !!policy && !!conditionTree && String(policy.ruleId) === ruleId

  const { pending } = useStoreState(
    (state) => state.ruleModel.policyHTTPStatuses.updatePolicy
  )

  const {
    getPolicy,
    handleFinishPolicyConditions,
    resetState,
    listPredictionLists,
  } = useStoreActions((actions) => actions.ruleModel)

  useEffect(() => {
    if (ruleId) {
      getPolicy({
        policy: { ruleId, viewLegacyVariables: false, isEditing },
        navigate,
      })
    }

    return () => {
      resetState()
    }
  }, [ruleId, getPolicy, resetState, isEditing, navigate])

  useEffect(() => {
    if (!teamId) {
      return
    }
    const params = getPredictionListParamsForTeam(teamId)

    listPredictionLists(params)
  }, [teamId, listPredictionLists])

  if (!isCurrentPolicy) {
    return null
  }

  if (!isPolicyConditionEditable && !isEditing) {
    onDenyEditRule()

    return null
  }

  const editReasonNotSupplied = isEditing && !editReason?.trim()

  return (
    <Layout>
      <TestTeamBanner
        teamId={policy?.teamId}
        text={t('common.testTeamBanner.policy')}
      />
      <PolicyHeader isEditing={isEditing} />
      <Content>
        <RulePageHeader isEditing={isEditing} />
        <Row gutter={32}>
          <Col span={6}>
            <NewRuleSteps current={0} />
          </Col>
          <Col span={18}>
            <RuleConditions isEditing={isEditing} />
            <RuleRecommendedActionSelect isEditing={isEditing} />
            {isEditing && (
              <>
                <Space size="md" />
                <EditReasonTextArea
                  onChange={(e) => setEditReason(e.target.value)}
                  editReason={editReason}
                />
                <Space size="md" />
              </>
            )}
          </Col>
        </Row>
      </Content>
      <Footer>
        <DiscardRuleCreationPopconfirm
          onConfirm={onDiscard}
          disabled={pending}
        />
        <Tooltip
          enabled={editReasonNotSupplied}
          placement="topRight"
          title={
            <div className={styles.tooltip}>
              <Text className={styles.text}>
                {t('ruleConditionsPage.editReason.tooltip')}
              </Text>
            </div>
          }
        >
          <Button
            data-test-id="finishRuleConditionsButton"
            loading={pending}
            type="primary"
            className={styles.nextButton}
            disabled={!nextStepButtonEnabled || editReasonNotSupplied}
            onClick={() =>
              handleFinishPolicyConditions({
                editReason,
                navigate,
                conditionTree,
              })
            }
          >
            {t('ruleConditionsPage.nextButton')}
          </Button>
        </Tooltip>
      </Footer>
    </Layout>
  )
}

export default PolicyConditionsPage
