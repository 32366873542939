import { action, actionOn, computed, thunk } from 'easy-peasy'
import { AxiosError } from 'axios'
import { message } from 'antd'
import {
  createRule as createRuleHTTP,
  GET_RULES_ORDER_BY,
  getDecisionCenterVariables,
  getRule as getRuleHTTP,
  listPredictionLists as listPredictionListsHTTP,
  RULE_STAGE,
  updateRule as updateRuleHTTP,
  Variable,
} from '@signifyd/http'
import { i18nInstance } from '@signifyd/components'
import {
  getHTTPErrorStatus,
  messageOnError,
  START_STATUS,
  SUCCESS_STATUS,
} from 'core/http'
import { CATCH_ALL_PATH } from 'core/constants'
import { convertToLegacyConditionTreeStructure } from 'pages/PolicyConditionsPage/PolicyConditionsPage.utils'
import {
  deserializePredicateToLegacyConditionTree,
  getRuleFeaturesByName,
  READONLY_RULE_FEATURES,
  serializeConditionTree,
} from '../conditionTree'
import {
  PolicyRuleFeatures,
  RuleModel,
  RuleState,
  UpdatePolicyPayload,
} from './rule.types'
import { DEFAULT_STATE } from './rule.store.state'
import {
  DEFAULT_RULE_PROPS,
  getDefaultPredictionListMap,
} from './rule.store.constants'

const mergeStateAndActions = (state: RuleState): RuleModel => ({
  ...state,

  // common actions
  setState: action((state, newState) => {
    Object.assign(state, newState)
  }),

  resetState: action((state) => {
    Object.assign(state, DEFAULT_STATE)
  }),

  createPolicy: thunk((_actions, payload, { fail }) => {
    const { policy, navigate } = payload

    return createRuleHTTP({ ...DEFAULT_RULE_PROPS, ...policy })
      .then(({ data }) => {
        // Create endpoint doesn't return enriched policy, which
        // includes ruleStatus, so we have to call get rule to get all the props.
        // TODO FET-1823 https://signifyd.atlassian.net/browse/FET-1823 remove getRuleHTTP after BE is fixed
        const { ruleId } = data

        return getRuleHTTP(ruleId)
      })
      .then(({ data: ruleResponse }) => {
        navigate(`/policies/create/${ruleResponse.ruleId}/conditions`)
      })
      .catch(fail)
  }),

  getPolicy: thunk((actions, payload, { fail }) => {
    const { policy, navigate } = payload
    const { ruleId, viewLegacyVariables, isEditing } = policy

    if (!ruleId) {
      navigate(CATCH_ALL_PATH)

      return Promise.reject()
    }

    actions.resetState()

    return getRuleHTTP(ruleId)
      .then(({ data: policyResponse }) => {
        const response = {
          policyResponse,
          viewLegacyVariables,
          isEditing,
        }

        actions.getFilterVariables(response)
      })
      .catch(() => {
        fail()
        navigate(CATCH_ALL_PATH)
      })
  }),

  updatePolicy: thunk((actions, payload) => {
    const { ruleId, ...rest } = payload

    return updateRuleHTTP(ruleId, rest)
      .then(({ data }) => {
        // TODO FET-1823 same as above comment - remove getRuleHTTP after BE is fixed
        const { ruleId } = data

        return getRuleHTTP(ruleId)
      })
      .then(({ data: policyResponse }) => {
        actions.getFilterVariables({ policyResponse })
      })
      .catch((e: AxiosError) => {
        throw e
      })
  }),

  updatePolicyDetails: thunk((actions, payload, { getState }) => {
    const { policy } = getState()
    if (!policy) {
      return Promise.reject()
    }

    return updateRuleHTTP(policy.ruleId, payload)
      .then(({ data: ruleResponse }) => {
        const updatedPolicy = {
          ...policy,
          name: ruleResponse.name,
          description: ruleResponse.description,
        }

        actions.setState({ policy: updatedPolicy })
      })
      .catch(
        messageOnError(
          i18nInstance.t('ruleCommon.fallbackErrorMessage.updateRule')
        )
      )
  }),

  listPredictionLists: thunk(async (actions, payload, { fail }) => {
    try {
      const {
        data: { data },
      } = await listPredictionListsHTTP({ ...payload, limit: 1000 })
      const predictionListMap = getDefaultPredictionListMap()

      data.forEach((predictionList) => {
        if (predictionList.type in predictionListMap) {
          predictionListMap[predictionList.type].push(predictionList)
        }
      })

      actions.setState({
        predictionListMap,
      })
    } catch (e) {
      fail(e)
    }
  }),

  selectedPredictionListName: computed(
    ({ predictionListMap }) =>
      (leafNode) => {
        const { predictionListFeature } = leafNode.ruleFeature || {}
        if (
          leafNode.value &&
          !Number.isNaN(+leafNode.value) &&
          predictionListMap &&
          predictionListFeature
        ) {
          const predictionList = predictionListMap[
            predictionListFeature?.type
          ].find((list) => list.id.toString() === leafNode.value?.toString())

          return predictionList?.name
        }

        return undefined
      }
  ),

  handleFinishPolicyValidation: thunk(
    (actions, payload, { getState, fail }) => {
      const { policyValidationPayload, navigate } = payload
      const {
        isEditing,
        checkpoint,
        matchingPolicyMatchIds,
        nonMatchingPolicyMatchIds,
      } = policyValidationPayload
      const { policy } = getState()

      if (!policy) {
        return Promise.reject()
      }

      const isPublished = !!policy.rankInActiveRuleSet

      const updatePolicyBody: UpdatePolicyPayload = {
        ruleId: policy.ruleId,
      }

      if (isEditing) {
        // If user is editing the policy validation, we'll be using the match IDs decided in the UI and send them
        // as draft versions.

        updatePolicyBody.draftMatchingPolicyMatchIds = matchingPolicyMatchIds
        updatePolicyBody.draftNonMatchingPolicyMatchIds =
          nonMatchingPolicyMatchIds

        // If policy isn't published, supply "applyEdits" in the update mask, but no value.
        if (!isPublished) {
          updatePolicyBody.applyEdits = undefined
        }
      } else {
        // Otherwise, we send them as new.
        updatePolicyBody.ruleStage = RULE_STAGE.NEW
        updatePolicyBody.nonMatchingPolicyMatchIds = nonMatchingPolicyMatchIds
        updatePolicyBody.matchingPolicyMatchIds = matchingPolicyMatchIds
      }

      return actions
        .updatePolicy(updatePolicyBody)
        .then(() => {
          const teamIdParam = `teamId=${policy.teamId}`
          const orderByParam = `orderBy=${GET_RULES_ORDER_BY.SORT_CREATED_AT}`
          const successModalParam = isEditing
            ? `editedPolicyId=${policy.ruleId}`
            : 'showNewRules=true'

          const checkpointParam = `checkpoint=${checkpoint}`
          const publishedOrderByParam = `publishedOrderBy=${GET_RULES_ORDER_BY.SORT_RANK}`
          const otherOrderByParam = `othersOrderBy=${GET_RULES_ORDER_BY.SORT_CREATED_AT}`

          const params = [
            teamIdParam,
            orderByParam,
            successModalParam,
            checkpointParam,
            publishedOrderByParam,
            otherOrderByParam,
          ]

          // If user is editing a policy and said policy is already published, we redirect them straight to simulation.
          if (isEditing && isPublished) {
            return navigate(
              `/policies/publish/${policy.teamId}/${checkpoint}?editedPolicyId=${policy.ruleId}`
            )
          }

          // Otherwise, they go back to the dashboard.
          return navigate(`/policies/dashboard?${params.join('&')}`)
        })
        .catch((e: AxiosError) => {
          messageOnError(
            i18nInstance.t('ruleCommon.fallbackErrorMessage.createRule')
          )(e)
          fail(e)
        })
    }
  ),

  handleFinishPolicyConditions: thunk(
    (actions, payload, { getState, fail }) => {
      const { editReason, navigate, conditionTree } = payload
      const { policy } = getState()

      const isEditing = !!editReason

      if (!policy || !conditionTree) {
        return Promise.reject()
      }

      const oldConditionTreeStructure =
        convertToLegacyConditionTreeStructure(conditionTree)
      const predicate = JSON.stringify(
        serializeConditionTree(oldConditionTreeStructure)
      )

      const updateRequestBody = isEditing
        ? { draftPredicate: predicate, editReason }
        : { predicate }

      const pageType = isEditing ? 'edit' : 'create'

      return actions
        .updatePolicy({
          name: policy.name,
          description: policy.description,
          ruleOutcome: policy.ruleOutcome,
          ruleId: policy.ruleId,
          ...updateRequestBody,
        })
        .then(() => {
          navigate(`/policies/${pageType}/${policy.ruleId}/validation`)
        })
        .catch((e: AxiosError) => {
          messageOnError(
            i18nInstance.t('ruleCommon.fallbackErrorMessage.createRule')
          )(e)
          fail(e)
        })
    }
  ),

  onRuleHTTP: actionOn(
    (actions) => [
      actions.getPolicy.failType,
      actions.createPolicy.startType,
      actions.createPolicy.successType,
      actions.createPolicy.failType,
      actions.updatePolicy.startType,
      actions.updatePolicy.successType,
      actions.handleFinishPolicyConditions.failType,
      actions.handleFinishPolicyValidation.failType,
    ],
    (state, target) => {
      const [
        GET_RULE_FAILED,
        CREATE_RULE_START,
        CREATE_RULE_SUCCESS,
        CREATE_RULE_FAILED,
        UPDATE_RULE_START,
        UPDATE_RULE_SUCCESS,
        FINISH_POLICY_CONDITIONS_FAIL,
        FINISH_POLICY_VALIDATION_FAIL,
      ] = target.resolvedTargets

      switch (target.type) {
        case GET_RULE_FAILED: {
          message.error({
            content: i18nInstance.t('ruleCommon.fallbackErrorMessage.getRule'),
            duration: 10,
          })
          break
        }
        case CREATE_RULE_START:
          state.policyHTTPStatuses.createPolicy = START_STATUS
          break
        case CREATE_RULE_SUCCESS:
          state.policyHTTPStatuses.createPolicy = SUCCESS_STATUS
          break
        case CREATE_RULE_FAILED: {
          const status = getHTTPErrorStatus(
            target.error as AxiosError,
            i18nInstance.t('ruleCommon.fallbackErrorMessage.createRule')
          )

          state.policyHTTPStatuses.createPolicy = status
          break
        }
        case UPDATE_RULE_START:
          state.policyHTTPStatuses.updatePolicy = START_STATUS
          break
        case UPDATE_RULE_SUCCESS:
          state.policyHTTPStatuses.updatePolicy = SUCCESS_STATUS
          break
        case FINISH_POLICY_CONDITIONS_FAIL:
        case FINISH_POLICY_VALIDATION_FAIL: {
          const status = getHTTPErrorStatus(
            target.error as AxiosError,
            i18nInstance.t('ruleCommon.fallbackErrorMessage.updateRule')
          )

          state.policyHTTPStatuses.updatePolicy = status
          break
        }
        default:
          break
      }
    }
  ),

  setRuleRecommendedAction: action((state, recommendedAction) => {
    if (!state.policy) {
      return
    }

    state.policy.ruleOutcome.ruleRecommendedAction = recommendedAction
  }),

  setStateFromPolicyResponse: action((state, payload): void => {
    try {
      const { policy, policyFeatures, isEditing }: PolicyRuleFeatures = payload

      if (!policy || !policyFeatures.length) {
        return
      }

      const predicateToParse = isEditing
        ? policy.draftPredicate
        : policy.predicate

      const predicate = JSON.parse(predicateToParse || '{}')

      const policyFeaturesByName = getRuleFeaturesByName(policyFeatures)

      const conditionTree = deserializePredicateToLegacyConditionTree(
        predicate,
        policyFeaturesByName
      )

      const newState = {
        ...DEFAULT_STATE,
        policy,
        conditionTree,
        policyFeatures: policyFeatures.filter(
          ({ name }) => !READONLY_RULE_FEATURES.has(name)
        ),
        policyFeaturesByName,
      }

      Object.assign(state, newState)
    } catch (e) {
      message.error(
        i18nInstance.t(
          'ruleCommon.fallbackErrorMessage.deserializeRulePredicate'
        )
      )
      Object.assign(state, DEFAULT_STATE)
    }
  }),

  getFilterVariables: thunk(async (actions, payload): Promise<void> => {
    const { policyResponse, viewLegacyVariables, isEditing } = payload
    try {
      const { checkpoint, teamId } = policyResponse

      const payload = {
        checkpoints: checkpoint,
        teamIds: teamId.toString(),
        isReadOnly: viewLegacyVariables,
        promoResellerTeamId: teamId,
      }

      const response = await getDecisionCenterVariables(payload)
      const filterData = response.data.data

      const sortFeatureByDescription = (a: Variable, b: Variable): number => {
        return a.description.localeCompare(b.description)
      }

      const sortedFilterVariables = [
        ...filterData.sort(sortFeatureByDescription),
      ]

      const policyWithFilterVariables = {
        policy: policyResponse,
        policyFeatures: sortedFilterVariables,
        isEditing,
      }

      actions.setStateFromPolicyResponse(policyWithFilterVariables)
    } catch {
      message.error(
        i18nInstance.t('ruleCommon.fallbackErrorMessage.filterVariables')
      )
    }
  }),

  onPredictionListsHTTP: actionOn(
    (actions) => [
      actions.listPredictionLists.startType,
      actions.listPredictionLists.successType,
      actions.listPredictionLists.failType,
    ],
    (state, target) => {
      const [
        GET_PREDICTION_LISTS_START,
        GET_PREDICTION_LISTS_SUCCESS,
        GET_PREDICTION_LISTS_FAILED,
      ] = target.resolvedTargets

      switch (target.type) {
        case GET_PREDICTION_LISTS_START:
          state.predictionListsHTTPStatuses.listPredictionLists = START_STATUS
          break
        case GET_PREDICTION_LISTS_SUCCESS:
          state.predictionListsHTTPStatuses.listPredictionLists = SUCCESS_STATUS
          break
        case GET_PREDICTION_LISTS_FAILED: {
          const status = getHTTPErrorStatus(
            target.error as AxiosError,
            i18nInstance.t(
              'ruleCommon.fallbackErrorMessage.listPredictionLists'
            )
          )

          state.predictionListsHTTPStatuses.listPredictionLists = status
          break
        }
        default:
          break
      }
    }
  ),
})

export default mergeStateAndActions
