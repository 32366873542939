import { FC, useState, useCallback } from 'react'
import { cond, constant, matches, stubTrue } from 'lodash'
import { Button, Modal } from 'antd'
import { useTranslation, Trans } from 'react-i18next'
import { RcFile } from 'antd/es/upload'
import {
  DUPLICATE_PREDICTION_LIST_ENTRY_ACTION,
  PredictionList,
} from '@signifyd/http'
import { useQueryClient } from '@tanstack/react-query'
import CloseListEntriesModalPopconfirm from 'core/components/CloseListEntriesModalPopconfirm'
import ListEntriesForm from 'pages/ListDetailsPage/components/ListEntriesForm'
import ResolveDuplicateEntriesForm from 'pages/ListDetailsPage/components/ResolveDuplicateEntriesForm'
import {
  SIG_MODAL_PROPS,
  UPLOAD_ENTRIES_METHOD,
  MAX_MANUAL_LIST_ENTRY_ITEMS,
} from 'core/constants'
import { useListDetailsPageQueryParams } from 'pages/ListDetailsPage/useListDetailsPageQueryParams'
import {
  useCreatePredictionListEntriesWithCSV,
  useCreatePredictionListEntriesWithJSON,
} from 'core/hooks/useCreatePredictionListEntries'
import { PREDICTION_LIST_ENTRIES_QUERY_KEY } from 'core/queries/queryKeys'

const duplicateEntryStatus = 409
export interface Props {
  onClose: () => void
  predictionList: PredictionList
  visible: boolean
}

const CreateListEntriesModal: FC<Props> = ({
  predictionList,
  visible,
  onClose,
}) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [query, setQuery] = useListDetailsPageQueryParams()
  const isNewList = query.isNewList === 'true'

  const [uploadEntriesMethod, setUploadEntriesMethod] = useState<
    UPLOAD_ENTRIES_METHOD | undefined
  >()
  const [manualInputValue, setManualInputValue] = useState<Array<string>>([])
  const [csvFile, setCSVFile] = useState<RcFile | undefined>()
  const [duplicateEntryAction, setDuplicateEntryAction] = useState<
    DUPLICATE_PREDICTION_LIST_ENTRY_ACTION | undefined
  >()
  const [isPartialComplete, setIsPartialComplete] = useState(false)

  const createListEntries = 'listDetailsPage.createListEntriesModal'
  const createEntriesCSV = useCreatePredictionListEntriesWithCSV()
  const createEntriesJSON = useCreatePredictionListEntriesWithJSON()

  const hasDuplicateEntries =
    createEntriesJSON.error?.response?.status === duplicateEntryStatus ||
    createEntriesCSV.error?.response?.status === duplicateEntryStatus

  const okButtonEnabled = cond([
    [
      matches({
        uploadEntriesMethod: UPLOAD_ENTRIES_METHOD.MANUAL,
        manualInputValueValid: true,
        hasDuplicateEntries: false,
      }),
      constant(true),
    ],
    [
      matches({
        uploadEntriesMethod: UPLOAD_ENTRIES_METHOD.CSV,
        csvValid: true,
        hasDuplicateEntries: false,
      }),
      constant(true),
    ],
    [
      matches({
        hasDuplicateEntries: true,
        duplicateEntryActionValid: true,
      }),
      constant(true),
    ],
    [stubTrue, constant(false)],
  ])({
    uploadEntriesMethod,
    manualInputValueValid:
      manualInputValue.length > 0 &&
      manualInputValue.length <= MAX_MANUAL_LIST_ENTRY_ITEMS,
    csvValid: !!csvFile,
    hasDuplicateEntries,
    duplicateEntryActionValid: !!duplicateEntryAction,
  })

  const resetState = useCallback((): void => {
    setUploadEntriesMethod(undefined)
    setManualInputValue([])
    setCSVFile(undefined)
    setDuplicateEntryAction(undefined)

    if (isNewList) {
      setQuery({ teamId: query.teamId, isNewList: null })
    }
  }, [isNewList, setQuery, query.teamId])

  const onUploadWithJSON = (
    values: Array<string>,
    duplicateEntryAction?: DUPLICATE_PREDICTION_LIST_ENTRY_ACTION
  ): void => {
    createEntriesJSON.mutate({
      predictionListId: predictionList.id,
      payload: {
        type: predictionList.type,
        values,
        duplicateEntryAction:
          duplicateEntryAction ??
          DUPLICATE_PREDICTION_LIST_ENTRY_ACTION.UNKNOWN,
      },
      onSuccessCallback: () => {
        queryClient.invalidateQueries([
          PREDICTION_LIST_ENTRIES_QUERY_KEY,
          predictionList.id.toString(),
        ])
        onClose()
      },
    })
  }

  const onUploadWithCSV = (
    file: RcFile,
    duplicateEntryAction?: DUPLICATE_PREDICTION_LIST_ENTRY_ACTION
  ): void => {
    createEntriesCSV.mutate({
      predictionListId: predictionList.id,
      type: predictionList.type,
      file,
      duplicateEntryAction,
      onSuccessCallback: () => {
        queryClient.invalidateQueries([
          PREDICTION_LIST_ENTRIES_QUERY_KEY,
          predictionList.id.toString(),
        ])
        onClose()
      },
    })
  }

  const onOk = (): void => {
    if (
      uploadEntriesMethod === UPLOAD_ENTRIES_METHOD.MANUAL &&
      okButtonEnabled
    ) {
      onUploadWithJSON(manualInputValue, duplicateEntryAction)
    } else if (uploadEntriesMethod === UPLOAD_ENTRIES_METHOD.CSV && csvFile) {
      onUploadWithCSV(csvFile, duplicateEntryAction)
    }
  }

  const onCancel = (): void => {
    if (!isPartialComplete) {
      onClose()
    }
  }

  const formTitleOptions = {
    type: t(`${createListEntries}.descriptionListType.${predictionList.type}`),
  }
  const formTitle = isNewList ? (
    <Trans
      i18nKey={`${createListEntries}.description.newList`}
      components={{ em: <em /> }}
      values={{
        type: formTitleOptions.type,
      }}
    />
  ) : (
    t(
      'listDetailsPage.createListEntriesModal.description.existingList',
      formTitleOptions
    )
  )

  const form = hasDuplicateEntries ? (
    <ResolveDuplicateEntriesForm
      value={duplicateEntryAction}
      onChange={setDuplicateEntryAction}
    />
  ) : (
    <ListEntriesForm
      csvError={createEntriesCSV?.error?.message}
      csvFile={csvFile}
      disabled={createEntriesJSON.isLoading || createEntriesCSV.isLoading}
      manualInputError={createEntriesJSON?.error?.message}
      manualInputValue={manualInputValue}
      manualInputValueCountLimit={MAX_MANUAL_LIST_ENTRY_ITEMS}
      onChangeUploadEntriesMethod={setUploadEntriesMethod}
      onChangeManualInputValue={setManualInputValue}
      onChangeCSVFile={setCSVFile}
      onPartialEntry={setIsPartialComplete}
      predictionListType={predictionList.type}
      title={formTitle}
      uploadEntriesMethod={uploadEntriesMethod}
    />
  )

  return (
    <Modal
      {...SIG_MODAL_PROPS}
      okButtonProps={{
        disabled: !okButtonEnabled,
        loading: createEntriesJSON.isLoading || createEntriesCSV.isLoading,
      }}
      title={t('listDetailsPage.createListEntriesModal.title')}
      open={visible}
      afterClose={resetState}
      footer={
        <>
          <CloseListEntriesModalPopconfirm
            disabled={!isPartialComplete}
            onConfirm={onCancel}
          >
            <Button onClick={onCancel} type="link">
              {t(`${createListEntries}.cancelText`)}
            </Button>
          </CloseListEntriesModalPopconfirm>
          <Button disabled={!okButtonEnabled} type="link" onClick={onOk}>
            {hasDuplicateEntries
              ? t(`${createListEntries}.okText.hasDuplicate`)
              : t(`${createListEntries}.okText.default`)}
          </Button>
        </>
      }
    >
      {form}
    </Modal>
  )
}

export default CreateListEntriesModal
