import { CHECKPOINT } from '@signifyd/http'

const checkpoint: Record<CHECKPOINT, string> & { label: string } = {
  label: 'Checkpoint',
  CHECKOUT: 'Checkout',
  LOGIN: 'Login',
  RETURN: 'Return',
}

export default {
  teamSelect: {
    label: 'Team (store)',
    notFoundContent: 'No teams (stores) found',
  },
  checkpoint,
  multiStageModalFooter: {
    ok: 'Continue',
    cancel: 'Cancel',
    previous: 'Previous',
  },
  routes: {
    lists: 'Lists',
    policies: 'Policies',
  },
  unauthorized: {
    default: 'You do not have permissions to access this page',
    noTeams: 'You need at least one active team to access Decision Center',
  },
  action: 'Action',
  deletePolicyModal: {
    title: 'Delete policy?',
    okText: 'Delete',
    alert: 'This action is permanent and cannot be undone.',
    message: 'To continue, select <bold>Delete</bold>.',
  },
  duplicatePolicy: {
    nameSuffix: ' (copy)',
  },
  duplicatePolicyModal: {
    title: 'Duplicate Policy',
    okText: 'Duplicate',
    failureAlert:
      'Apologies - policy failed to duplicate to the following teams. Please try again',
    listAlert:
      'Policies that contain a list may only be duplicated to the same team.',
    select: {
      currentSuffix: '(Current)',
      label: 'Select teams to duplicate policy to:',
      placeholder: 'Select one or more teams',
      disabledOption:
        'Variables not available in this team - policy cannot be duplicated',
      tryAgainTooltip:
        "Teams can't be adjusted. To continue, try duplicating again.",
    },
    successMessage: 'Success! Policy has been duplicated.',
  },
  additionalItemsPopover: {
    noItems: 'Empty',
  },
  testTeamBanner: {
    testTeamSelected: 'Test team <bold>({{teamName}})</bold> selected',
    testTeamSelectedWithNestedText:
      'Test team <bold>({{teamName}})</bold> selected. {{text}}',
    policy:
      'Policies created on this team will not affect live customer orders.',
  },
}
